import styled from 'styled-components';

import fundo from '../../images/home/body/fundo2.png';

export const Container = styled.div`
  @media (min-width: 1024px) {
    background-image: url(${fundo});
    margin-top: 0;
    height: 710px;
  }
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
  background-repeat: no-repeat;
`;

export const Body = styled.div`
  height: auto;
  width: 100%;
`;

export const Content = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1024px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Apresentation = styled.p`
  @media (min-width: 1024px) {
    font-size: 46px;
    text-align: left;
    color: #fff;
  }
  color: #364c61;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  font-family: 'Segoe UI';
`;

export const ContactBtn = styled.button`
  border: 1px solid #85bbed;
  margin: 25px 0 20px 0;
  justify-content: center;
  border-radius: 100px;
  padding: 3px 15px;
  display: flex;
  width: 225px;
  color: #01314e;
  @media (min-width: 1024px) {
    background-color: transparent;
    color: #85bbed;
    &:hover {
      background-color: #85bbed;
      box-shadow: 0 5px 5px 0 #aaa;
      position: relative;
      font-weight: bold;
      bottom: 10px;
      right: 10px;
      color: #fff;
    }
  }
`;

export const TitleBtn = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 5px 0;
`;

export const Contact = styled.div`
  /* @media (min-width: 1024px) { */
  margin: 15px 100px;
  height: 200px;
  background-color: red;
  /* } */
`;

export const Colun = styled.div`
  /* @media (min-width: 1024px) {
    display: none;
  } */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 80px;
  height: 225px;
  padding: 15px;
  width: 25%;
`;

export const Card = styled.div`
  @media (min-width: 1024px) {
    width: 33%;
    height: 275px;
    margin: 0 80px;
  }
  width: 250px;
  height: 175px;
  display: flex;
  margin: 0 30px;
  padding: 5px 15px;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 0.5px 3px 0 #9999;
`;

export const Row = styled.div`
  @media (min-width: 1024px) {
    padding: 0 10%;
  }
  display: flex;
  margin-top: 25px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
`;

export const Icon = styled.img`
  /* @media (min-width: 1024px) {
    display: none;
  } */
  width: 60px;
  height: 60px;
  margin: 5px 0;
`;

export const TitleView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  color: #222d;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 15px;
  border-top: 3.5px solid #099e64;
`;

export const SliderView = styled.div`
  width: 100%;
  padding: 0 5%;
`;

export const ServiceDescription = styled.p`
  @media (min-width: 1024px) {
    font-weight: 100;
    text-align: left;
    display: block;
  }
  display: none;
`;

export const DescriptionView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: left;
`;

export const ContactView = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(#f78e40cc, #387173cc);
`;

export const ContactLine = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px;
`;

export const ContactTitle = styled.p`
  border-top: 1px solid #000;
  font-weight: bold;
  font-size: 22px;
`;

export const ContactText = styled.p`
  margin-top: 15px;
  font-weight: 600;
  color: #111;
  text-align: ${(props) => props.align || 'center'};
`;

export const ContactIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 50px;
`;

export const ButtonTitle = styled.p`
  color: #ea5a3c;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
`;

export const ContactButton = styled.button`
  width: 150px;
  height: 50px;
  border: none;
  display: flex;
  margin-top: 30px;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  justify-content: center;
  &:hover {
    border: 0.5px solid #ea5a3c;
  }
`;
