/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

// style
// import { TextField } from '@mui/material';
import {
  Body,
  Colun,
  Container,
  Content,
  LocalImage,
  TelefoneTxt,
  EndTxt,
  Icon,
  Row,
  Map,
  Title,
  TitleView,
  Apresentation,
  ContactView,
  // FormView,
  // FormTitle,
  // FormTitleView,
  // FormColun,
  // FormRow,
  // FormEspacamento,
  // FromButton,
} from './style';

// components
import MenuBar from '../../components/menuBar';
import Footer from '../../components/footer';

import localImg from '../../images/contact/local.jpeg';
import localIcon from '../../images/contact/localIcon.png';
import telIcon from '../../images/contact/telIcon.png';

function Contact() {
  return (
    <div>
      <Body>
        <MenuBar />
        <Container>
          <Content>
            <Row>
              <Colun>
                <TitleView>
                  <Title>Venha nos fazer uma visita</Title>
                </TitleView>
                <LocalImage src={localImg} />
              </Colun>
              <Colun align="flex-start">
                <Apresentation>
                  Nós adoraríamos ouvir sobre isso.
                  <br /> Entre em contato para que possamos conhecê-lo e
                  entender seu projeto.
                </Apresentation>
                <ContactView>
                  <Row>
                    <Icon src={telIcon} />
                    <TelefoneTxt>(11) 4872-8255</TelefoneTxt>
                  </Row>
                  <Row margin="5px">
                    <Icon src={localIcon} />
                    <EndTxt>
                      Alameda Rio Negro, 1030 - escritório 206 - Condomínio
                      Stadium
                      <br />
                      CEP 06454-000 - Alphaville / Barueri / SP
                    </EndTxt>
                  </Row>
                </ContactView>
                <Map src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d14635.922650002622!2d-46.848295!3d-23.497206!3m2!1i1024!2i768!4f13.1!2m1!1sAlameda%20Rio%20Negro%2C%20%201030%20-%20escrit%C3%B3rio%20206%20-%20Condom%C3%ADnio%20Stadium%20-%20CEP%2006454-000%20-%20Alphaville%20%2F%20Barueri%20%2F%20SP!5e0!3m2!1spt-BR!2sbr!4v1650392908456!5m2!1spt-BR!2sbr" />
              </Colun>
            </Row>
            {/* <Row align="flex-start">
              <FormView>
                <FormTitleView>
                  <FormTitle>Contato</FormTitle>
                </FormTitleView>
                <FormRow>
                  <FormColun>
                    <TextField
                      id="outlined-basic"
                      label="Nome"
                      variant="outlined"
                    />
                    <FormEspacamento />
                    <TextField
                      id="outlined-basic"
                      label="Empresa"
                      variant="outlined"
                    />
                  </FormColun>
                  <FormColun>
                    <TextField
                      id="outlined-basic"
                      label="Telefone"
                      variant="outlined"
                    />
                    <FormEspacamento />
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </FormColun>
                </FormRow>
                <FormEspacamento />
                <FormRow>
                  <FormColun width="200%">
                    <TextField
                      id="outlined-multiline-static"
                      label="Comentário ou Mensagem"
                      multiline
                      rows={4}
                    />
                  </FormColun>
                  <FormColun>
                    <FromButton>
                      <p>botão</p>
                    </FromButton>
                  </FormColun>
                </FormRow>
              </FormView>
            </Row> */}
          </Content>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </Body>
    </div>
  );
}

export default Contact;
