/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

// style
import {
  Body,
  Container,
  Title,
  TitleView,
  Apresentation,
  Card,
  CardContainer,
  CardTitle,
  ImgReuniao,
  Colun,
  Row,
  SubTitle,
  Section,
  MiniCard,
  Text,
  MiniCardTitle,
  TitleContainer,
  CardTitleView,
  CardTitleBorder,
  AboutText,
  ServicesBtn,
  ServicesTitle,
} from './style';

// components
import MenuBar from '../../components/menuBar';
import Footer from '../../components/footer';

import fundo from '../../images/about/img1.jpg';

function About() {
  return (
    <div>
      <Body>
        <MenuBar />
        <Container>
          <TitleContainer>
            <TitleView>
              <Title>A Loyalt</Title>
              <ImgReuniao src={fundo} />
            </TitleView>
          </TitleContainer>
          <Colun align="left">
            <Apresentation>
              <AboutText fontSize="21px">
                A Loyalt é uma empresa privada de suporte de TI e serviços de TI
                especializada em Meios de
                <br /> Pagamento , Adquirência, Banco Digital , Varejo , Venda
                Direta.
              </AboutText>
              <br />
              <AboutText>
                Hoje, temos orgulho de contar com uma equipe sólida de
                engenheiros de TI
                <br /> que se dedicam a arregaçar as mangas e resolver seus
                problemas de TI
                <br /> e atender às suas necessidades de negócios.
              </AboutText>
            </Apresentation>
          </Colun>
          <CardContainer>
            <Card>
              <CardTitleView>
                <CardTitle>MISSÃO</CardTitle>
                <CardTitleBorder />
              </CardTitleView>
              <p>
                Prover Soluções de TI mais adequadas para o alcance dos
                objetivos de empresas e o atendimento das necessidades dos
                clientes.
              </p>
            </Card>
            <Card right>
              <CardTitleView>
                <CardTitle>VISÃO</CardTitle>
                <CardTitleBorder />
              </CardTitleView>
              <p>
                Ser a empresa de Gestão de TI que melhor entrega serviços
                customizados de acordo com o segmento de negócio dos clientes.
              </p>
            </Card>
            <Card>
              <CardTitleView>
                <CardTitle>VALORES</CardTitle>
                <CardTitleBorder />
              </CardTitleView>
              <p>
                Confiança e credibilidade Compromisso com resultados
                Transparência nos processos Integridade e ética
              </p>
            </Card>
          </CardContainer>
          <Colun>
            <Section left="100px">
              <SubTitle>Por que o nossos serviços?</SubTitle>
              <Text font>
                Temos um processo comprovado para ajudar você a <br />
                impulsionar sua empresa e estamos com você em todas <br />
                as etapas do processo.
              </Text>
            </Section>
          </Colun>
          {/* <Row coluna> */}
          <Row>
            <Colun>
              <MiniCard>
                <MiniCardTitle>SUPORTE</MiniCardTitle>
                <Text>
                  Nossos engenheiros são dedicados e<br /> experientes e irão
                  esforçar-se para
                  <br /> resolver seus problemas de TI.
                </Text>
              </MiniCard>
            </Colun>
            <Colun>
              <MiniCard>
                <MiniCardTitle>QUALIDADE</MiniCardTitle>
                <Text>
                  Compromisso de fornecer soluções
                  <br /> de TI de ponta e excepcionais que
                  <br /> agregam valor real que vai além do esperado.
                </Text>
              </MiniCard>
            </Colun>
            <Colun>
              <MiniCard>
                <MiniCardTitle>SERVIÇO AO CLIENTE</MiniCardTitle>
                <Text>
                  Nós nos esforçamos para fornecer
                  <br /> um serviço superior ao cliente e<br /> garantir que
                  cada cliente esteja
                  <br /> completamente satisfeito com
                  <br /> nosso trabalho.
                </Text>
              </MiniCard>
            </Colun>
          </Row>
          <Link to="/services" style={{ textDecoration: 'none' }}>
            <ServicesBtn>
              <ServicesTitle>Conheça nossos produtos</ServicesTitle>
            </ServicesBtn>
          </Link>
        </Container>
        <br />
        <br />
        <Footer />
      </Body>
    </div>
  );
}

export default About;
