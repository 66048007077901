/* eslint-disable import/extensions */
import React from 'react';

import AppRoutes from './routes/routes';

function App() {
  return <AppRoutes />;
}

export default App;
