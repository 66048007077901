import styled from 'styled-components';

export const Body = styled.div`
  height: auto;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const TitleView = styled.div`
  @media (min-width: 1024px) {
    align-items: flex-start;
  }
  width: 100%;
  height: auto;
  display: flex;
  padding: 35px 75px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff5;
`;

export const Title = styled.h1`
  color: #222d;
  font-size: 48px;
  text-align: left;
  font-weight: bold;
  border-top: 3px solid #099e64;
`;

export const Colun = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.align};
`;

export const Row = styled.div`
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
  }
  width: 100%;
  display: flex;
  margin: 0 50px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const Card = styled.button`
  border: 0;
  width: ${(props) => (props.show ? '380px' : '250px')};
  height: ${(props) => (props.show ? '310px' : '250px')};
  font-weight: 600;
  margin: 15px 25px;
  padding: 15px 25px;
  text-align: center;
  background-color: #fff;
  border: 0.5px solid #aaa5;
  box-shadow: 0 0.5px 3px 0 #aaa;
  @media (min-width: 1024px) {
    &:hover {
      box-shadow: 5px 5px 5px 3px #d85d4444;
      border: 0.5px solid #a2cafc77;
      position: relative;
      bottom: 5px;
      right: 5px;
    }
  }
`;
export const CardIcon = styled.img`
  width: 50px;
  height: 50px;
  position: relative;
  bottom: 5px;
  right: 5px;
`;

export const CardTitle = styled.p`
  border-top: 1px solid ${(props) => (props.blue ? '#3f4f63' : '#d85d44')};
  color: ${(props) => (props.blue ? '#3f4f63' : '#d85d44')};
  font-weight: 800;
  margin-top: 15px;
  font-size: 20px;
`;

export const CardText = styled.p`
  font-weight: 500;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #3c70b0;
`;
