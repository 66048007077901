/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';

// style
import {
  Body,
  Card,
  CardIcon,
  CardText,
  CardTitle,
  Container,
  Row,
  Text,
  Title,
  TitleView,
} from './style';

// components
import MenuBar from '../../components/menuBar';
import Footer from '../../components/footer';

import cartao from '../../images/services/cartaoIcon.png';
import equipe from '../../images/services/equipeIcon.png';
import processo from '../../images/services/processoIcon.png';
import aloc from '../../images/services/alocIcon.png';
import conformidade from '../../images/services/conformidadeIcon.png';

function Services() {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  function click1() {
    if (show1) {
      setShow1(false);
    } else {
      setShow1(true);
      setShow2(false);
      setShow3(false);
      setShow4(false);
      setShow5(false);
    }
  }

  function click2() {
    if (show2) {
      setShow2(false);
    } else {
      setShow1(false);
      setShow2(true);
      setShow3(false);
      setShow4(false);
      setShow5(false);
    }
  }

  function click3() {
    if (show3) {
      setShow3(false);
    } else {
      setShow1(false);
      setShow2(false);
      setShow3(true);
      setShow4(false);
      setShow5(false);
    }
  }

  function click4() {
    if (show4) {
      setShow4(false);
    } else {
      setShow1(false);
      setShow2(false);
      setShow3(false);
      setShow4(true);
      setShow5(false);
    }
  }

  function click5() {
    if (show5) {
      setShow5(false);
    } else {
      setShow1(false);
      setShow2(false);
      setShow3(false);
      setShow4(false);
      setShow5(true);
    }
  }

  return (
    <div>
      <Body>
        <MenuBar />
        <Container>
          <TitleView>
            <Title>NOSSOS SERVIÇOS</Title>
          </TitleView>
          <Row>
            <Card onClick={() => click1()} show={show1}>
              <CardIcon src={cartao} />
              <CardTitle>Solução de Bancarização</CardTitle>
              {show1 ? (
                <CardText>
                  Possuímos expertise em todos os processos da
                  <br /> cadeia de processamento de cartões, desde a<br />
                  autorização da transação junto à bandeira e<br /> bancos
                  emissores até a liquidação financeira
                  <br /> e demais processos de back office.
                </CardText>
              ) : (
                <Text>Saiba mais {'>'}</Text>
              )}
            </Card>
            <Card onClick={() => click2()} show={show2}>
              <CardIcon src={equipe} />
              <CardTitle blue>Transformação Digital</CardTitle>
              {show2 ? (
                <CardText>
                  Estudamos como seu negócio pode ser
                  <br /> transformado tecnologicamente e em quais
                  <br /> pontos será preciso atuar, seja adequando
                  <br /> serviços existentes, seja desenvolvendo outros
                  <br /> totalmente novos.
                </CardText>
              ) : (
                <Text>Saiba mais {'>'}</Text>
              )}
            </Card>
            <Card onClick={() => click3()} show={show3}>
              <CardIcon src={processo} />
              <CardTitle>Sustentação de Processos</CardTitle>
              {show3 ? (
                <CardText>
                  Disponibilizamos profissionais para
                  <br /> desempenhar o trabalho no site do cliente, de
                  <br /> acordo com o perfil requerido, nas diversas
                  <br />
                  plataformas tecnológicas e áreas de atuação.
                </CardText>
              ) : (
                <Text>Saiba mais {'>'}</Text>
              )}
            </Card>
          </Row>
          <Row>
            <Card onClick={() => click4()} show={show4}>
              <CardIcon src={aloc} />
              <CardTitle blue>Alocação de profissionais</CardTitle>
              {show4 ? (
                <CardText>
                  Possuímos grande expertise na monitoração
                  <br /> de sistemas produtivos em todas as
                  <br /> plataformas tecnológicas, desde o mainframe
                  <br /> passando pelas plataformas Linux e Windows.
                </CardText>
              ) : (
                <Text>Saiba mais {'>'}</Text>
              )}
            </Card>
            <Card onClick={() => click5()} show={show5}>
              <CardIcon src={conformidade} />
              <CardTitle>Conformidade LGPD</CardTitle>
              {show5 ? (
                <CardText>
                  Auxiliamos corporações em todo o processo
                  <br /> de conformidade a Lei Geral de Proteção de
                  <br /> Dados (Lei nº 13.709) entrará em vigor à partir
                  <br /> de agosto de 2020.
                </CardText>
              ) : (
                <Text>Saiba mais {'>'}</Text>
              )}
            </Card>
            {/* <Card>
              <CardIcon src={cartao} />
              <CardText>teste</CardText>
            </Card> */}
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </Body>
    </div>
  );
}

export default Services;
