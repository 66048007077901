/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

// style
import {
  Body,
  Container,
  Content,
  Apresentation,
  ContactBtn,
  TitleBtn,
  Icon,
  DescriptionView,
  Row,
  Title,
  TitleView,
  ServiceDescription,
  Card,
  Description,
  ContactView,
  ContactContainer,
  ContactText,
  ContactTitle,
  ContactLine,
  ContactIcon,
  ContactButton,
  ButtonTitle,
} from './style';

// components
import MenuBar from '../../components/menuBar';
import Footer from '../../components/footer';
// import Slider from '../../components/slider';

// icons
// import provedor from '../../images/home/icons/provedorIcon.png';
// import suporte from '../../images/home/icons/suporteIcon.png';
// import euqipe from '../../images/home/icons/equipeIcon.png';
// import cartao from '../../images/home/icons/cartaoIcon.png';
import alocacao from '../../images/home/icons/alocIcon.png';
import conform from '../../images/home/icons/conformidadeIcon.png';
import processo from '../../images/home/icons/processoIcon.png';
import star from '../../images/home/icons/starIcon.png';
import localIcon from '../../images/contact/localIcon.png';
import telIcon from '../../images/contact/telIcon.png';

function Home() {
  return (
    <div>
      <Body>
        <MenuBar />
        <Container>
          <Content>
            <Apresentation>
              A solução para
              <br /> o seu negócio!
            </Apresentation>
            {/* button */}
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <ContactBtn>
                <TitleBtn>Vamos conversar?</TitleBtn>
              </ContactBtn>
            </Link>
          </Content>
        </Container>
        <br />
        <br />
        {/* mini serviços */}
        <TitleView>
          <Title>NOSSOS SERVIÇOS</Title>
        </TitleView>
        <Row>
          <Card>
            <Icon src={alocacao} />
            <p>Alocação de profissionais</p>
            <ServiceDescription>
              Disponibilizamos profissionais para desempenhar o trabalho no site
              do cliente, de acordo com o perfil requerido, nas diversas
              plataformas tecnológicas e áreas de atuação.
            </ServiceDescription>
          </Card>
          <Card>
            <Icon src={processo} />
            <p>Sustentação de Processos</p>
            <ServiceDescription>
              Possuímos grande expertise na monitoração de sistemas produtivos
              em todas as plataformas tecnológicas, desde o mainframe passando
              pelas plataformas Linux e Windows.
            </ServiceDescription>
          </Card>
          <Card>
            <Icon src={conform} />
            <p>Conformidade LGPD</p>
            <ServiceDescription>
              Auxiliamos corporações em todo o processo de conformidade a Lei
              Geral de Proteção de Dados (Lei nº 13.709) entrará em vigor à
              partir de agosto de 2020.
            </ServiceDescription>
          </Card>
        </Row>
        <br />
        <br />
        {/* mini especialidade */}
        <TitleView>
          <Title>NOSSA ESPECIALIDADE</Title>
        </TitleView>
        <DescriptionView>
          <Description>
            Possuímos expertise em todos os processos da cadeia de processamento
            de cartões,
            <br /> desde a autorização da transação junto à bandeira e bancos
            emissores até a liquidação <br />
            financeira e demais processos de back office.
          </Description>
        </DescriptionView>
        <Row>
          <Card>
            <Icon src={star} />
            <p>
              O ERP Vision Plus <br /> Processamento de cartões
            </p>
          </Card>
          <Card>
            <Icon src={star} />
            <p>
              IST-Switch, Postilion e Base24 <br /> Autorização
            </p>
          </Card>
          <Card>
            <Icon src={star} />
            <p>
              CMM e Payware <br /> Processamento e Liquidação
            </p>
          </Card>
        </Row>
        <ContactView>
          <ContactContainer>
            <ContactTitle>CONTATO</ContactTitle>
            <ContactText>
              Pare de se preocupar com problemas de tecnologia e concentre-se no
              <br />
              seu negócio. Deixe-nos fornecer o apoio que você merece.
            </ContactText>
            <ContactLine>
              <ContactIcon src={telIcon} />
              <ContactText>(11) 4872-8255</ContactText>
            </ContactLine>
            <ContactLine>
              <ContactIcon src={localIcon} />
              <ContactText align="left">
                Alameda Rio Negro, 1030 - escritório 206 - Condomínio Stadium
                <br />
                CEP 06454-000 - Alphaville / Barueri / SP
              </ContactText>
            </ContactLine>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <ContactButton>
                <ButtonTitle>Fale Conosco</ButtonTitle>
              </ContactButton>
            </Link>
          </ContactContainer>
        </ContactView>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </Body>
    </div>
  );
}

export default Home;
