import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  height: auto;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  /* padding: 5px 50px; */
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled.div`
  @media (min-width: 1024px) {
    flex-direction: row;
    text-align: left;
  }
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${(props) => props.margin || '0'};
  align-items: ${(props) => props.align || 'center'};
`;

export const Colun = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.align || 'center'};
`;

export const Title = styled.h1`
  color: #222d;
  font-weight: 900;
  border-top: 3px solid #099e64;
`;

export const TitleView = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 35px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

export const LocalImage = styled.img`
  @media (min-width: 1024px) {
    width: 60%;
  }
  width: 100%;
  height: auto;
  justify-content: center;
`;

export const ContactView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

export const TelefoneTxt = styled.p``;
export const EndTxt = styled.p``;

export const Icon = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 50px;
`;

export const Apresentation = styled.p`
  color: #222d;
  font-size: 21px;
  font-weight: 500;
  margin-top: 75px;
`;

export const Map = styled.iframe`
  @media (min-width: 1024px) {
    width: 60%;
    height: 275px;
  }
  border: 0;
  width: 100%;
  height: 350px;
  margin-top: 40px; ;
`;

export const FormView = styled.div`
  width: 100%;
  height: auto;
  padding: 5px 10%;
  margin-top: 50px;
  margin-bottom: 75px;
`;

export const FormTitle = styled.h2`
  color: #222d;
  /* font-size: 22px; */
  font-weight: 900;
  border-top: 3px solid #099e64;
`;

export const FormTitleView = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 35px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
`;

export const FormColun = styled.div`
  width: ${(props) => props.width || '100%'};
  display: flex;
  padding: 0 50px 10px 0;
  flex-direction: column;
`;

export const FormRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

export const FormEspacamento = styled.div`
  width: 100%;
  height: 35px;
`;

export const FromButton = styled.button`
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  border: 1px solid #099e64;
  background-color: transparent;
  box-shadow: 0 0.5px 3px 0 #aaa;
`;
