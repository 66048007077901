import React from 'react';
import { Link } from 'react-router-dom';

import {
  Background,
  SocialIcons,
  Icon,
  MapText,
  Endereco,
  Menu,
  Logo,
  EndTxt,
  Button,
  LgpdView,
  LgpdText,
  Body,
  LgpdLink,
  ContactView,
  Coluna,
  ContactTxt,
} from './style';

import logo from '../../images/logo.png';
import twitter from '../../images/icons/twitter.png';
import linkedin from '../../images/icons/linkedin.png';
import instagram from '../../images/icons/instagram.png';
import lgpd from '../../lgpd/privacidade.pdf';

function Footer() {
  return (
    <div>
      <Background>
        <Coluna>
          <Body>
            {/* ÍCONES DE REDES SOCIAIS */}
            <SocialIcons>
              <Icon src={twitter} />
              <Icon src={linkedin} />
              <Icon src={instagram} />
            </SocialIcons>
            <Menu>
              <Button>
                <Link to="/home" style={{ textDecoration: 'none' }}>
                  <MapText>HOME</MapText>
                </Link>
              </Button>
              <Button>
                <Link to="/about" style={{ textDecoration: 'none' }}>
                  <MapText>SOBRE NÓS</MapText>
                </Link>
              </Button>
              <Button>
                <Link to="/services" style={{ textDecoration: 'none' }}>
                  <MapText>SERVIÇOS</MapText>
                </Link>
              </Button>
              <Button>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <MapText>CONTATO</MapText>
                </Link>
              </Button>
            </Menu>
            <Endereco>
              <EndTxt>Alameda Rio Negro, 1030</EndTxt>
              <EndTxt>CEP 06454-000</EndTxt>
              <EndTxt>Alphaville - SP</EndTxt>
            </Endereco>
            <Logo src={logo} />
            <ContactView>
              <ContactTxt>comercial@loyalt.com.br</ContactTxt>
              <ContactTxt>(11) 1234-56578</ContactTxt>
            </ContactView>
          </Body>
          <LgpdView>
            <LgpdLink href={lgpd}>
              <LgpdText>Política de Privacidade</LgpdText>
            </LgpdLink>
          </LgpdView>
        </Coluna>
      </Background>
    </div>
  );
}

export default Footer;
