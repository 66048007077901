/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Background,
  BigMenu,
  Button,
  Drawer,
  Icon,
  Logo,
  MiniMenu,
  SocialIcons,
  Txt,
} from './style';

import logo from '../../images/logo.png';
import twitter from '../../images/icons/twitter.png';
import linkedin from '../../images/icons/linkedin.png';
import instagram from '../../images/icons/instagram.png';
import MenuIcon from '../../images/icons/menuIcon.svg';

function MenuBar() {
  const [home, setHome] = useState(false);
  const [sobre, setSobre] = useState(false);
  const [servico, setServico] = useState(false);
  const [contato, setContato] = useState(false);
  const [show, setShow] = useState(false);

  function homeSet() {
    setHome(true);
    setSobre(false);
    setServico(false);
    setContato(false);
  }

  function aboutSet() {
    setHome(false);
    setSobre(true);
    setServico(false);
    setContato(false);
  }

  function serviceSet() {
    setHome(false);
    setSobre(false);
    setServico(true);
    setContato(false);
  }

  function contactSet() {
    setHome(false);
    setSobre(false);
    setServico(false);
    setContato(true);
  }

  function drwaerClick() {
    setShow(!show);
  }

  return (
    <div>
      <Background>
        <Drawer onClick={() => drwaerClick()}>
          <img src={MenuIcon} alt="MenuIcon" height="50px" />
        </Drawer>
        {/* LOGO */}
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <Logo src={logo} />
        </Link>
        {/* menu 1080p */}
        <BigMenu>
          {/* BOTÃO DA HOME */}
          <Button firstMargin="50%" onClick={() => homeSet()} selected={home}>
            <Link to="/home" style={{ textDecoration: 'none' }}>
              <Txt selected={home}>HOME</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE CATÁLOGO DE PRODUTOS */}
          <Button onClick={() => aboutSet()} selected={sobre}>
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <Txt selected={sobre}>SOBRE NÓS</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE SOLUÇÕES EFETUADAS */}
          <Button onClick={() => serviceSet()} selected={servico}>
            <Link to="/services" style={{ textDecoration: 'none' }}>
              <Txt selected={servico}>SERVIÇOS</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE SOBRE A EMPRESA */}
          <Button onClick={() => contactSet()} selected={contato}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <Txt selected={contato}>CONTATO</Txt>
            </Link>
          </Button>
        </BigMenu>
        {/* ÍCONES DE REDES SOCIAIS */}
        <SocialIcons>
          <Icon src={twitter} />
          <Icon src={linkedin} />
          <Icon src={instagram} />
        </SocialIcons>
      </Background>
      {/* menu 720p */}
      {show ? (
        <MiniMenu>
          {/* BOTÃO DA HOME */}
          <Button firstMargin="50%" onClick={() => homeSet()}>
            <Link to="/home" style={{ textDecoration: 'none' }}>
              <Txt selected={home}>HOME</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE CATÁLOGO DE PRODUTOS */}
          <Button onClick={() => aboutSet()}>
            <Link to="/about" style={{ textDecoration: 'none' }}>
              <Txt selected={sobre}>SOBRE NÓS</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE SOLUÇÕES EFETUADAS */}
          <Button onClick={() => serviceSet()}>
            <Link to="/services" style={{ textDecoration: 'none' }}>
              <Txt selected={servico}>SERVIÇOS</Txt>
            </Link>
          </Button>
          {/* BOTÃO DE SOBRE A EMPRESA */}
          <Button onClick={() => contactSet()}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <Txt selected={contato}>CONTATO</Txt>
            </Link>
          </Button>
        </MiniMenu>
      ) : (
        <div />
      )}
    </div>
  );
}

export default MenuBar;
