import styled from 'styled-components';

export const Fixo = styled.div`
  position: fixed;
`;

export const Body = styled.div`
  height: auto;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const TitleView = styled.div`
  @media (min-width: 1024px) {
    width: auto;
    /* height: 500px; */
    display: flex;
    padding: 35px 75px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff5;
  }
  height: auto;
  /* display: none; */
`;

export const Title = styled.h1`
  color: #222d;
  font-size: 48px;
  text-align: left;
  font-weight: bold;
  border-top: 3px solid #099e64;
`;

export const ImgReuniao = styled.img`
  @media (min-width: 1024px) {
    box-shadow: 3px 2px 5px 0 #d85d4455;
    display: block;
  }
  display: none;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 50px 0 0 20%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Colun = styled.div`
  @media (min-width: 1024px) {
    width: 50%;
  }
  width: 90%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.align || 'center'};
`;

export const Row = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    padding: 0 10%;
    margin-top: -100px;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
  flex-direction: column;
`;

export const Apresentation = styled.div`
  @media (min-width: 1024px) {
    margin-left: -22%;
  }
  width: 100%;
  display: flex;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CardContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-around;
  }
`;

export const Card = styled.div`
  @media (min-width: 1024px) {
    text-align: center;
    padding: 15px 25px;
    font-weight: 600;
    width: 20%;
  }
  align-items: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.right ? 'right' : 'left')};
  justify-content: center;
  margin: 20px 40px;
  width: 90%;
`;

export const CardTitleBorder = styled.div`
  border-bottom: 3px #728dad solid;
  width: 75px;
`;
export const CardTitleView = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const CardTitle = styled.h2`
  @media (min-width: 1024px) {
    border-bottom: 0;
    color: #222d;
  }
  font-weight: 900;
  color: #d85d44;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  @media (min-width: 1024px) {
    flex-direction: column;
    padding: 15px 25px;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SubTitle = styled.h2`
  @media (min-width: 1024px) {
    border-top: 3px solid #d85d44;
    border-bottom: 1px #fff solid;
  }
  color: #222d;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
  border-bottom: 1px #d85d44 solid;
`;

export const MiniCard = styled.div`
  @media (min-width: 1024px) {
    border-radius: 10px;
    width: auto;
    display: flex;
    text-align: left;
    padding: 10px 25px;
    align-items: center;
    margin-bottom: 40px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0.5px 1px 0 #0007;
  }
  text-align: center;
`;

export const MiniCardTitle = styled.h1`
  @media (min-width: 1024px) {
    font-size: 20px;
  }
  color: #d85d44;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  border-top: 0.5px #099e64 solid;
`;

export const Text = styled.p`
  @media (min-width: 1024px) {
    font-size: ${(props) => (props.font ? '22px' : '18px')};
  }
  font-weight: 400;
  font-size: ${(props) => (props.font ? '20px' : '16px')};
`;

export const AboutText = styled.p`
  font-size: ${(props) => props.fontSize || ' 18px'};
  font-weight: 400;
`;

export const ServicesBtn = styled.button`
  width: 350px;
  height: 75px;
  border-radius: 60px;
  background-color: white;
  border: 1px solid #099e64;
  &:hover {
    right: 5px;
    bottom: 5px;
    position: relative;
    background-color: #099e64;
    box-shadow: 0 2px 3px 0 #d85d44;
  }
`;

export const ServicesTitle = styled.p`
  color: #222d;
  font-size: 22px;
  margin-top: 10px;
  font-weight: bold;
`;
