/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 5px 15px;
  background: #1E2633;
  align-items: center;
  justify-content: space-around;
`;

export const Logo = styled.img`
  @media (min-width: 1024px) {
    height: 85px;
    margin: 15px;
  }
  height: 60px;
  margin: 10px;
`;

export const BigMenu = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    width: 100%;
    padding: 0 15px;
    justify-content: space-around;
  }
  display: none;
`;

export const Drawer = styled.button`
  @media (min-width: 1024px) {
    display: none;
  }
  border: none;
  background-color: transparent;
`;

export const MiniMenu = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  @media (min-width: 1024px) {
    width: 10%;
    height: 60%;
    border: none;
    padding: 0 20px;
    margin-top: 10px;
    text-align: center;
    align-items: center;
    border-right: 1.5px solid #4d6d75;
    margin-left: ${(props) => props.firstMargin || '0'};
    &:hover {
      position: relative;
      color: #d8593d;
      bottom: 5px;
      right: 5px;
    }
  }
  border: none;
  border-bottom: 1.5px solid #4d6d75;
  background-color: #1E2633;
`;

export const Txt = styled.p`
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  &:hover {
    color: #d8593d;
  }
`;

export const SocialIcons = styled.div`
  @media (min-width: 1024px) {
    width: 40px;
    height: 100%;
    display: flex;
    margin-top: 15px;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  display: none;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px 0;
`;
