import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 15px 5%;
  justify-content: space-around;
`;

export const Background = styled.div`
  @media (min-width: 1024px) {
    justify-content: space-around;
  }
  flex-direction: row;
  height: auto;
  display: flex;
  padding: 15px 5%;
  background: #1E2633;
  justify-content: space-between;
`;

export const Coluna = styled.div`
  width: 100%;
  color: #fff;
  margin: 3px;
  height: 100%;
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  justify-content: space-between;
`;

export const Menu = styled.div`
  @media (min-width: 1024px) {
    width: 15%;
    color: #fff;
    margin: 3px;
    height: 100%;
    display: flex;
    padding: 5px 0;
    flex-direction: column;
    justify-content: space-between;
  }
  display: none;
`;

export const Endereco = styled.div`
  @media (min-width: 1024px) {
    width: 15%;
    color: #fff;
    margin: 3px;
    height: 100%;
    display: flex;
    padding: 5px 0;
    flex-direction: column;
    justify-content: space-between;
  }
  display: none;
`;

export const Logo = styled.img`
  @media (min-width: 1024px) {
    width: 15%;
    height: 10%;
    margin: 15px;
  }
  width: 30%;
  height: 10%;
  margin: 15px;
`;

export const SocialIcons = styled.div`
  @media (min-width: 1024px) {
    width: 40px;
    height: auto;
    margin-top: 15px;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  /* display: none; */
`;

export const Icon = styled.img`
  margin: 7px;
  width: 30px;
  height: 30px;
`;

export const Button = styled.button`
  border: none;
  text-align: left;
  align-items: flex-start;
  background-color: transparent;
  &:hover {
    position: relative;
    color: #d8593d;
    bottom: 5px;
    right: 5px;
  }
`;

export const MapText = styled.p`
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
  color: #fff;
`;

export const EndTxt = styled.p`
  font-size: 18px;
  margin-top: 17px;
  font-weight: 400;
`;

export const ContactView = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  width: 25%;
  color: #fff;
  margin: 3px;
  height: 100%;
  display: flex;
  padding: 5px 0;
  margin-right: 10%;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContactTxt = styled.div`
  font-size: 18px;
  margin-top: 17px;
  font-weight: 400;
`;

export const LgpdView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LgpdLink = styled.a`
  text-decoration: none;
`;

export const LgpdText = styled.p`
  color: #374a63;
  font-weight: 200;
  font-family: Montserrat;
  &:hover {
    border-bottom: 1px solid #374a63;
  }
`;
